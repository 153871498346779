const newEventReducer = (
  state = {
    name: "",
    creatorDescription: "",
    priority: "1",
    requestedFinishDate: new Date(),
    eventFiles: [],
  },
  action
) => {
  switch (action.type) {
    case "setNewEventName":
      return { ...state, name: action.payload.name };
    case "setNewEventCreatorDescription":
      return {
        ...state,
        creatorDescription: action.payload.creatorDescription,
      };
    case "setNewEventPiority":
      return { ...state, priority: action.payload.priority };
    case "setNewEventRequestedFinishDate":
      return {
        ...state,
        requestedFinishDate: action.payload.requestedFinishDate,
      };
    case "setNewEventFiles":
      return { ...state, eventFiles: action.payload.eventFiles };
    case "resetNewEvent":
      return (state = {
        name: "",
        creatorDescription: "",
        priority: "1",
        requestedFinishDate: new Date(),
        eventFiles: [],
      });
    default:
      return state;
  }
};

export default newEventReducer;
