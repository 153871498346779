import axios, { AxiosResponse } from "axios";

import { createParams } from "@winshop-crm/lib";
import {
  EventsFilterRequest,
  EventsFilterResponse,
  TasksFilterRequest,
  TasksFilterResponse,
} from "@winshop-crm/types";
export type ApiReturnType<T> = { res?: Response; data?: T; err?: Error };

const API_URL = `${process.env.NEXT_PUBLIC_URL}/api`;

export function api() {
  return api;
}

api.events = async (request: EventsFilterRequest) => {
  return await api.sharedFetch<EventsFilterResponse[]>(
    `/events${createParams({ ...request, numberOfEvents: "1000" })}`
  );
};

api.event = async (eventId: string) => {
  return await api.sharedFetch<EventsFilterResponse[]>(`/events/${eventId}}`);
};

api.tasks = async (request: TasksFilterRequest) => {
  return await api.sharedFetch<TasksFilterResponse[]>(
    `/tasks${createParams({ ...request, numberOfTasks: "1000" })}`
  );
};

api.task = async ({ userId, token, taskId }: { userId: string; token: string; taskId: string }) => {
  return await api.sharedFetch<EventsFilterResponse[]>(
    `/tasks/${taskId}${createParams({ userId: userId, token: token })}`
  );
};

// eslint-disable-next-line @typescript-eslint/ban-types
api.sharedFetch = async <T = {}>(path: string): Promise<T> => {
  const url = API_URL + path;
  const result = (await _axiosGet<T>(url)).data;

  return result;
};

// eslint-disable-next-line @typescript-eslint/ban-types
const _axiosGet = <T = {}>(url: string): Promise<AxiosResponse<T>> =>
  axios
    .get<T>(url, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(async (res) => {
      if (res.status >= 400) throw new Error(JSON.stringify(res.data) ?? res.statusText);
      return res;
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.warn(err);
      return err;
    });
