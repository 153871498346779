import { isDefined } from "./is-defined";

export const createParams = (params?: Record<string, string | string[]>): string => {
  const filteredParams = Object.entries(params ?? {}).reduce((acc, [key, value]) => {
    if (isDefined(value) && value != "") acc[key] = value;
    return acc;
  }, {} as Record<string, string | string[]>);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const paramsString = new URLSearchParams(filteredParams as any).toString();
  if (!paramsString) return "";
  return "?" + paramsString;
};
