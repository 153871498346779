import { removeBlanksFromObj } from "@winshop-crm/lib";

export const initialState = {
  userFilters: null,
  tasksFilterId: null,
  eventsFilterId: null,
  tasksFilter: {
    taskId: "",
    taskName: "",
    userIDs: "",
    taskStates: "",
    taskPriorities: "",
    companyIDs: "",
    projectIDs: "",
    createdByUserIDs: "",
    primarySolverUserIDs: "",
    secondarySolverUserIDs: "",
    controllingUserIDs: "",
    approvingUserIDs: "",
    bilingUserIDs: "",
    u1: "",
    u2: "",
    u3: "",
    u4: "",
    u5: "",

    requestedStartOfRealizationDateFrom: null,
    requestedStartOfRealizationDateTo: null,
    startOfRealizationDateFrom: null,
    startOfRealizationDateTo: null,
    requestedFinishDateFrom: null,
    requestedFinishDateTo: null,
    finishDateFrom: null,
    finishDateTo: null,
    handoverToCreatorDateFrom: null,
    handoverToCreatorDateTo: null,
  },
  eventsFilter: {
    eventId: "",
    eventName: "",
    userIDs: "",
    eventStates: "",
    companyIDs: "",
    projectIDs: "",
    createdByUserIDs: "",
    primarySolverUserIDs: "",
    secondarySolverUserIDs: "",
    controllingUserIDs: "",
    approvingUserIDs: "",
    bilingUserIDs: "",
    s1: "",
    s2: "",
    s3: "",
    s4: "",
    s5: "",

    creationDateFrom: null,
    creationDateTo: null,
    finishDateFrom: null,
    finishDateTo: null,
  },
};

export const filtersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "setUserFilters":
      return { ...state, userFilters: action.payload.userFilters };
    case "setSelectedTasksFilterId":
      return { ...state, tasksFilterId: action.payload.tasksFilterId };
    case "setSelectedEventsFilterId":
      return { ...state, eventsFilterId: action.payload.eventsFilterId };
    case "setTasksFilter":
      return { ...state, tasksFilter: action.payload.tasksFilter };
    case "resetTasksFilter":
      return { ...state, tasksFilter: initialState.tasksFilter };
    case "setEventsFilter":
      return { ...state, eventsFilter: removeBlanksFromObj(action.payload.eventsFilter) };
    case "resetEventsFilter":
      return { ...state, eventsFilter: initialState.eventsFilter };
    default:
      return state;
  }
};
