import isPropValid from "@emotion/is-prop-valid";
import { SessionProvider as NextAuthSessionProvider } from "next-auth/react";
import { Provider as ReduxProvider } from "react-redux";
import { StyleSheetManager } from "styled-components";

import "../../styles/globals.css";
import "../components/Common/table/EnhancedTable.scss";
import "../../styles/quill.winshop.scss";

import { ErrorBoundary } from "../components/Layouts/ErrorBoundary";
import { useStore } from "../utils/store";

function MyApp({ Component, pageProps }) {
  const store = useStore(pageProps.initialReduxState);

  return (
    <ErrorBoundary>
      <StyleSheetManager shouldForwardProp={shouldForwardProp}>
        <NextAuthSessionProvider session={pageProps.session}>
          <ReduxProvider store={store}>
            <Component {...pageProps} />
          </ReduxProvider>
        </NextAuthSessionProvider>
      </StyleSheetManager>
    </ErrorBoundary>
  );
}

export default MyApp;

function shouldForwardProp(propName, target) {
  if (typeof target === "string") {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
}
