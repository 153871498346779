import { combineReducers } from "redux";

import { filtersReducer } from "./filters";
import itemListsReducer from "./itemLists";
import newEventReducer from "./newEvent";
import userReducer from "./user";

const allReducers = combineReducers({
  newEvent: newEventReducer,
  user: userReducer,
  itemLists: itemListsReducer,
  filters: filtersReducer,
});

export default allReducers;
