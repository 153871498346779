const intitialState = { optionalItemLists: null, companiesItemList: null };

const itemListReducer = (state = intitialState, action) => {
  switch (action.type) {
    case "setOptionalItemLists":
      return { ...state, optionalItemLists: action.payload.optionalItemLists };
    case "setCompaniesItemList":
      return { ...state, companiesItemList: action.payload.companiesItemList };
    case "setEventStatesItemList":
      return { ...state, eventStatesItemList: action.payload.eventStatesItemList };
    case "setTaskStatesItemList":
      return { ...state, taskStatesItemList: action.payload.taskStatesItemList };
    case "setTaskPrioritiesItemList":
      return { ...state, taskPrioritiesItemList: action.payload.taskPrioritiesItemList };
    case "setUsersItemList":
      return { ...state, usersItemList: action.payload.usersItemList };
    case "resetItemLists":
      return (state = intitialState);
    default:
      return state;
  }
};

export default itemListReducer;
